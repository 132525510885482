import React from 'react'

const Terms = () => {
    return (
        <div>
           <br /> 
           <br /> 
           <br /> 
           <br /> 
            <h3>Axcess Fund Terms of Services</h3>
            <br /> 
           <br /> 
           <br /> 
           <br /> <br /> 
           <br /> 
           <br /> 
           <br /> 
        </div>
    )
}

export default Terms
