export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Sign up Today',
  headline: 'Join today to receive an exclusive email when we lunch beta version',
  description:
    'Get aXcess to our exclusive MVP version. Limited users available.',
  buttonLabel: 'Sign Up',
  imgStart: '',
  img: require('../../images/svg-2.svg'),
  alt: 'Credit Card'
};

export const homeObjNine = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: '100% Secure',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Anywhere and Anytime, get Axcess to the capital you need instantly. 100% All-Digital!',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/lock.svg'),
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: 'Easy Setup',
  headline: 'Super fast and simple onboarding process',
  description:
    "Get everything set up and funded in minutes. All you need to do is provide few personal information to verify and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: 'Data Analytics',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-1.svg'),
  alt: 'Vault'
};
