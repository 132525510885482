import React from 'react'

const Privacy = () => {
    return (
        <div>
           <br />
           <br />
           <br />
           <br />
            <h1>Axcess Fund Privacy Policy </h1>
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default Privacy
