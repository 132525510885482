import React from 'react'

const Resources = () => {
    return (
        <div>
            Here you can find tons of resources you need.
        </div>
    )
}

export default Resources
