import React from 'react'

const License = () => {
    return (
        <div>
            <br />
            <br />
            <br />
            <h1>Licenses, Notices, and Related Disclosures</h1>
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default License
