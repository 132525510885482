import React from 'react'

function CustomerStories() {
  return (
    <div>CustomerStories
        <h1>Read stories to see people are using Axcess Fund to transform their financial goal.

</h1>
    </div>
  )
}

export default CustomerStories