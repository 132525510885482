 
export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Bill Now, Pay Later',
  headline: 'Get funded to cover essential needs!',
  description:
    'In a typical year, landlords file 3.7 million eviction cases. Axcess Fund provide Instant-decision to emergency fund you need at affordable rate & flexible repayment option',
    buttonLabel: 'CHECK YOUR RATE',
   imgStart: '',
  img: require('../../images/2.svg'),
  alt: 'Fund Card',
  start: ''
};

export const homeObjLand = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: ' ',
  headline: ' Boost Cash Flow - Retain & Keep Happy Residents',
  description:
    'With Axcess Lending platform, we provide the easiest way to capture and report the on-time rental data to credit bureaus. This helps residents build credit scores while you maximize your cash flow & support ESG effort!',
  buttonLabel: 'Schedule demo',
   imgStart: '',
  img: require('../../images/cashflow.png'),
  alt: 'Fund Card',
  start: ''
};
export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Easy Signups & Instant Decision',
  headline: 'Instant-axcess to emergency fund!',
  description:
    "Get instant axcess to emergency fund to cover essential needs (rent/utilities). With few personal information, you'll be able to see instantly how much you've been approved and instanly pay bill directly through the the portal! We do the heavy lifting so you don't have to!",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-2.svg'),
  alt: 'Vault',
  start: ''
};

export const homeObjFive = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'More ways to earns while helping other!',
  headline: 'Earn up to $250 by referring friends',
  description:
    "Invite friends to use Axcess Fund platform and earn up to $250 when they get funded in Amazon.com gift cards. And your friends get $30 gift card of their own, just for signing up to use platform to pay rent/mortgage!",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/111.svg'),
  alt: 'Vault',
  start: ''
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Life is unpredictable',
  headline:
    'Our Vision is to keep families togther!',
  description:
    "56% of Americans are shut-down by mainstream banking for funding! Axcess Fund helps families keep family together during difficult time by providing platform to get instant funding to pay the essential bills so they can focus on what matter to them, themselves!",
  buttonLabel: 'How this works?',
  imgStart: 'start',
  img: require('../../images/stat.jpeg'),
  alt: 'Vault',
  start: 'true'
};
export const homeObjCashback = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    'Cash Back everyday.',
  description:
    "Do you feel like you are throwing away your Rent's cash every month? Your bank doesn't care, but we do! Get up-to 5% cash-back using Axcess Debit Card ",
  buttonLabel: 'How this works?',
  imgStart: 'start',
  img: require('../../images/cashback.png'),
  alt: 'Vault',
  start: 'true'
};

export const homeObjRenter = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Renter',
  headline:
    'Build credit by renting',
  description:
    "Axcess Fund Help people build credit through renting, which is the most consistent payment you make every month. You deserve credit (+scores)!",
  buttonLabel: 'How this works?',
  imgStart: 'start',
  img: require('../../images/creditscore.jpeg'),
  alt: 'Vault',
  start: 'true'
};


 

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Security is Key!',
  headline: 'All your data is stored on our secure server',
  description:
    'Our team of security experts will ensure your records are kept safe.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-3.svg'),
  alt: 'Vault',
  start: 'true'
};


 